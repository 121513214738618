export default {
  movie_route: "/es/peliculas/{slug}",
  serie_route: "/es/series/{slug}",
  movie_route_play: "/es/peliculas/{slug}/play",
  movie_route_play_trailer: "/es/peliculas/{slug}/play/trailer",
  serie_route_play: "/es/series/{slug}/play",
  serie_route_play_trailer: "/es/series/{slug}/play/trailer",
  episode_route: "/es/series/{slug}/season-{season}/episode-{episode}",
  episode_route_play:
    "/es/series/{slug}/temporada-{season}/episodio-{episode}/play",
  browse_by_country: "/es/descubre/{country}",
  routes: {
    home: "/es/",
    home_logged: "/es/home",
    discover: "/es/descubre",
    browse: "/es/navegar",
    browse_movies: "/es/navegar/peliculas",
    browse_by_countrie: "/es/navegar-por-pais",
    browse_series: "/es/navegar/series",
    my_list: "/es/mi-lista",
    search: "/es/buscar",
    about_us: "/es/sobre-nosotros",
    privacy_policy: "/es/politica-de-privacidad",
    terms_and_conditions: "/es/terminos-y-condiciones",
    plans: "/es/planes",
    plans_logged: "/es/plans-logged",
    register: "/es/registro",
    login: "/es/iniciar-sesion",
    logout: "/es/logout",
    forgot_password: "/es/olvide-contrasena",
    forgot_password_sent: "/es/olvide-contrasena-enviado",
    faq: "https://help.flixlatino.com/hc/es/sections/360009133974-Preguntas-Frecuentes",
    get_the_full_experience: "/es/obten-la-experiencia-completa",
    miff: "/es/miff",
    miami: "/es/miami",
    premiosplatino: "/es/premiosplatino",
  },
  affiliates: {
    landing: "https://flixlatino.com/affiliate-program/es"
  },
  trending: {
    landing: "https://flixlatino.com/tendencias/es/",
    top_movies: "https://flixlatino.com/tendencias/es/mejores-peliculas",
    top_movies_now:
      "https://flixlatino.com/tendencias/es/mejores-peliculas-ahora",
    top_tv_shows: "https://flixlatino.com/tendencias/es/mejores-series-de-tv",
    top_tv_shows_now:
      "https://flixlatino.com/tendencias/es/mejores-series-de-tv-ahora",
  },
};
